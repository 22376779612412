import { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer, List } from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import { DashboardNavbarMenuItem } from './dashboard-navbar-menu-item';
import { Cog as CogIcon } from '../icons/cog';
import { User as UserIcon } from '../icons/user';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { CustomCube as CubeIcon } from '../icons/custom-cube';
import { Cash as CashIcon } from '../icons/cash';
import { CustomChartPie as ChartPieIcon } from '../icons/custom-chart-pie';
import { CustomShoppingCart as ShoppingCartIcon } from '../icons/custom-shopping-cart';
import { CustomUsers as UsersIcon } from '../icons/custom-users';
import { Chip as ChipIcon } from '../icons/chip';
import { DocumentText as DocumentTextIcon } from '../icons/document-text';
import { OfficeBuilding as OfficeBuildingIcon } from '../icons/office-building';
import { CustomCreditCard as CustomCreditCardIcon } from '../icons/custom-credit-card';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Mail } from '../icons/mail';
import { useMerchantContext } from '../contexts/merchant-context';
import { Calendar } from '../icons/calendar';

const itemsInternal = [
  {
    icon: ChartPieIcon,
    title: 'Summary',
    items: [
      {
        href: '/summary/analytics',
        title: 'analytics'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'User Management',
    items: [
      {
        href: '/users',
        title: 'Users'
      },
      {
        href: 'users/group',
        title: 'User Group'
      }
    ]
  },
  {
    icon: OfficeBuildingIcon,
    title: 'Merchants',
    items: [
      {
        href: '/merchants',
        title: 'List'
      }
    ]
  },
  {
    icon: CustomCreditCardIcon,
    title: 'Billing',
    items: [
      {
        href: '/merchants/billing/accounts',
        title: 'Accounts'
      },
      {
        href: '/merchants/billing/transactions',
        title: 'Transactions'
      },
      {
        href: '/merchants/billing/invoices',
        title: 'Invoices'
      },
      {
        href: '/merchants/billing/bills',
        title: 'Bills'
      },
      {
        href: '/merchants/billing/categories',
        title: 'Categories'
      },
    ]
  }
];

const itemsExternal = [
  {
    icon: ChartPieIcon,
    title: 'Summary',
    items: [
      {
        href: '/summary',
        title: 'Sales'
      },
      {
        href: '/summary/analytics',
        title: 'Analytics'
      },
      {
        href: '/summary/e-commerce',
        title: 'E-Commerce'
      },
      {
        href: '/summary/cashiers',
        title: 'Cashiers'
      }
    ]
  },
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales',
        title: 'POS'
      },
      {
        href: '/pos/register',
        title: 'Register'
      },
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      },
      {
        href: '/pos/sales-payments/matched',
        title: 'Sales Payments'
      },
      {
        href: '/pos/ecommerce-orders',
        title: 'E-commerce Orders'
      }
    ]
  },
  {
    icon: CubeIcon,
    title: 'Products & Services',
    items: [
      {
        href: '/products/list',
        title: 'Product List'
      },
      {
        href: '/products/services/list',
        title: 'Services List'
      },
      {
        href: '/products/add',
        title: 'Add Product or Service'
      },
      {
        href: '/products/import-products',
        title: 'Import/Export Products'
      },
      {
        href: '/products/category',
        title: 'Categories'
      },
      {
        href: '/products/variations',
        title: 'Variations'
      },
      {
        href: '/products/units',
        title: 'Units'
      },
      {
        href: '/products/brands',
        title: 'Brands'
      },
      {
        href: '/products/custom-fields',
        title: 'Custom Fields'
      },
      {
        href: '/products/print-labels',
        title: 'Print Labels'
      }
    ]
  },
  {
    icon: ShoppingBagIcon,
    title: 'Purchases',
    items: [
      {
        href: '/purchase/list',
        title: 'Purchase List'
      },
      {
        href: '/purchase/return',
        title: 'Purchase Return'
      },
      {
        href: '/purchase/suppliers',
        title: 'Suppliers'
      },
      {
        href: '/purchase/etims',
        title: 'Etims purchases'
      }
    ]
  },
  {
    icon: CashIcon,
    title: 'Expenses',
    items: [
      {
        href: '/expenses/list',
        title: 'List'
      },
      {
        href: '/expenses/categories',
        title: 'Categories'
      }
    ]
  },
  {
    icon: Mail,
    title: 'Bulk Messages',
    items: [
      {
        href: '/messages/messages-history',
        title: 'Messages History',
      },
      {
        href: '/messages/bulk-message',
        title: 'Bulk Message'
      }
    ]
  },
  {
    icon: Calendar,
    title: 'Bookings',
    items: [
      {
        href: '/bookings/list',
        title: 'List'
      },
      {
        href: '/bookings/calendar',
        title: 'Calendar'
      },
      {
        href: '/bookings/settings',
        title: 'Branch Settings'
      },
      {
        href: '/bookings/ui-settings',
        title: 'Banner Settings'
      }
    ]
  },
  {
    icon: UserIcon,
    title: 'CRM',
    items: [
      {
        href: '/crm/customers/list',
        title: 'Customers'
      },
      {
        href: '/crm/customers/categories',
        title: 'Categories'
      },
      {
        href: '/crm/customers/import-customers',
        title: 'Import Customers'
      },
      {
        href: '/crm/customers/custom-fields',
        title: 'Custom Fields'
      },
      {
        href: '/crm/subscription-plans',
        title: 'Subscription Plans'
      }
    ]
  },
  {
    icon: DocumentTextIcon,
    title: 'Stock Management',
    items: [
      {
        href: '/stock-management/branch-stock-monitoring',
        title: 'Branch Stock Monitoring'
      },
      {
        href: '/stock-management/direct-stock-update',
        title: 'Direct Stock Update'
      },
      {
        href: '/stock-management/transfer',
        title: 'Stock Transfer'
      },
      {
        href: '/stock-management/opening-stock',
        title: 'Import Opening Stock'
      },
      {
        href: '/stock-management/stock-adjustment/',
        title: 'Stock Adjustment'
      },
      {
        href: '/stock-management/branch-stock-take',
        title: 'Branch Stock Take'
      },
      {
        href: '/stock-management/etims-move-list',
        title: 'Etims Move List'
      }
    ]
  },
  {
    icon: ChartBarIcon,
    title: 'Reports',
    items: [
      {
        href: '/reports/sales',
        title: 'Sales Reports'
      },
      {
        href: '/reports/stock',
        title: 'Stock Reports'
      },
      {
        href: '/reports/expenses',
        title: 'Expenses Reports'
      },
      {
        href: '/reports/tax',
        title: 'Tax Reports'
      },
      {
        href: '/reports/standard-reports',
        title: 'Standard Reports'
      },
      {
        href: '/reports/historical-registers-report',
        title: 'Register Reports'
      },
      {
        href: '/reports/price-lists',
        title: 'Price Lists Report'
      },
      {
        href: '/reports/income-statement',
        title: 'Income statement'
      },
      // {
      //   href: '/reports/pfms',
      //   title: 'PFMS'
      // },
    ]
  },
  {
    icon: UsersIcon,
    title: 'Staff & Commissions',
    items: [
      {
        href: '/staff/',
        title: 'Staff List'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'User Management',
    items: [
      {
        href: '/users/list',
        title: 'Users'
      },
      {
        href: '/users/user/group',
        title: 'User Group'
      }
    ]
  },
  {
    icon: OfficeBuildingIcon,
    title: 'Branch Management',
    items: [
      {
        href: '/branches/list',
        title: 'Branch List'
      },
      {
        href: '/branches/etims-list',
        title: 'Etims Branch List'
      },
      {
        href: '/etims/insurance-list',
        title: 'Etims Insurance List'
      }

    ]
  },
  {
    icon: CustomCreditCardIcon,
    title: 'Billing',
    badge: 'NEW',
    href: '/billing'
  },
  {
    icon: ChipIcon,
    title: 'Plugins',
    href: '/plugins'
  },
  {
    icon: CogIcon,
    title: 'Settings',
    items: [
      {
        href: '/settings/pos-settings',
        title: 'POS Settings'
      },
      {
        href: '/settings/bulk-message-settings',
        title: 'Bulk Messaging Settings'
      },
      {
        href: '/settings/notifications',
        title: 'Notifications'
      }
    ]
  }
];

const branchAdmin = [
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales',
        title: 'POS'
      },
      {
        href: '/pos/register',
        title: 'Register'
      },
      {
        href: '/pos/sales-payments/matched',
        title: 'Sales Payments'
      },
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      },
      {
        href: '/pos/ecommerce-orders',
        title: 'E-commerce Orders'
      },
    ]
  },
  {
    icon: Receipt,
    title: 'Invoices',
    items: [
      {
        href: '/invoices/create',
        title: 'Create Invoice'
      },
      {
        href: '/invoices/list',
        title: 'Invoice List'
      },
      {
        href: '/invoices/details',
        title: 'Invoice Details'
      }
    ]
  },
  {
    icon: CubeIcon,
    title: 'Products & Services',
    items: [
      {
        href: '/products/list',
        title: 'Product List'
      },
      {
        href: '/products/services/list',
        title: 'Services List'
      },
      {
        href: '/products/print-labels',
        title: 'Print Labels'
      }
    ]
  },
  {
    icon: ShoppingBagIcon,
    title: 'Purchases',
    items: [
      {
        href: '/purchase/list',
        title: 'Purchase List'
      },
      {
        href: '/purchase/return',
        title: 'Purchase Return'
      },
      {
        href: '/purchase/suppliers',
        title: 'Suppliers'
      },
      // ToDo! Add merchant data to hide this menue when the setting is of
      {
        href: '/purchase/etims',
        title: 'Etims purchases'
      }
    ]
  },
  {
    icon: CashIcon,
    title: 'Expenses',
    items: [
      {
        href: '/expenses/list',
        title: 'List'
      }
    ]
  },
  {
    icon: UserIcon,
    title: 'CRM',
    items: [
      {
        href: '/crm/customers/list',
        title: 'Customers'
      },
      {
        href: '/crm/customers/categories',
        title: 'Categories'
      },
      {
        href: '/crm/customers/import-customers',
        title: 'Import Customers'
      },
      {
        href: '/crm/customers/custom-fields',
        title: 'Custom Fields'
      },
    ]
  },
  {
    icon: DocumentTextIcon,
    title: 'Stock Management',
    items: [
      {
        href: '/stock-management/branch-stock-monitoring',
        title: 'Branch Stock Monitoring'
      },
      {
        href: '/stock-management/direct-stock-update',
        title: 'Direct Stock Update'
      },
      {
        href: '/stock-management/transfer',
        title: 'Stock Transfer'
      },
      {
        href: '/stock-management/opening-stock',
        title: 'Opening Stock'
      }
    ]
  },
  {
    icon: CogIcon,
    title: 'Settings',
    items: [
      {
        href: '/settings/pos-settings',
        title: 'POS Settings'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Staff & Commissions',
    items: [
      {
        href: '/staff/',
        title: 'Staff List'
      }
    ]
  },
];

const cashier = [
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales',
        title: 'POS'
      },
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      }
    ]
  },
  {
    icon: CubeIcon,
    title: 'Products & Services',
    items: [
      {
        href: '/products/print-labels',
        title: 'Print Labels'
      }
    ]
  },
  {
    icon: UserIcon,
    title: 'CRM',
    items: [
      {
        href: '/crm/customers/list',
        title: 'Customers'
      },
    ]
  },
];

const accountant = [
  {
    icon: ChartPieIcon,
    title: 'Summary',
    items: [
      {
        href: '/summary',
        title: 'Sales'
      },
      {
        href: '/summary/analytics',
        title: 'Analytics'
      },
      {
        href: '/summary/e-commerce',
        title: 'E-Commerce'
      },
      {
        href: '/summary/cashiers-performance',
        title: 'Cashiers\' Performance'
      }
    ]
  },
  {
    icon: ShoppingCartIcon,
    title: 'Sale',
    items: [
      {
        href: '/pos/sales-history',
        title: 'Sales History'
      },
      {
        href: '/pos/sales-payments/matched',
        title: 'Sales Payments'
      },
      {
        href: '/pos/ecommerce-orders',
        title: 'E-commerce Orders'
      }
    ]
  },
  {
    icon: ShoppingBagIcon,
    title: 'Purchases',
    items: [
      {
        href: '/purchase/list',
        title: 'Purchase List'
      },
      {
        href: '/purchase/return',
        title: 'Purchase Return'
      }
    ]
  },
  {
    icon: CashIcon,
    title: 'Expenses',
    items: [
      {
        href: '/expenses/list',
        title: 'List'
      }
    ]
  },
  {
    icon: DocumentTextIcon,
    title: 'Stock Management',
    items: [
      {
        href: '/stock-management/branch-stock-monitoring',
        title: 'Branch Stock Monitoring'
      },
      {
        href: '/stock-management/direct-stock-update',
        title: 'Direct Stock Update'
      },
      {
        href: '/stock-management/transfer',
        title: 'Stock Transfer'
      },
      {
        href: '/stock-management/stock-adjustment/',
        title: 'Stock Adjustment'
      },
      {
        href: '/stock-management/branch-stock-take',
        title: 'Branch Stock Take'
      },
      {
        href: '/stock-management/etims-move-list',
        title: 'Etims Move List'
      },
    ]
  },
  {
    icon: ChartBarIcon,
    title: 'Reports',
    items: [
      {
        href: '/reports/sales',
        title: 'Sales Reports'
      },
      {
        href: '/reports/stock',
        title: 'Stock Reports'
      },
      {
        href: '/reports/expenses',
        title: 'Expenses Reports'
      },
      {
        href: '/reports/tax',
        title: 'Tax Reports'
      },
      {
        href: '/reports/standard-reports',
        title: 'Standard Reports'
      },
      {
        href: '/reports/price-lists',
        title: 'Price Lists Report '
      },
    ]
  }
];

export const DashboardNavbarMenu = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');
  const { merchantData } = useMerchantContext();
  const [menu, setMenu] = useState([]);

  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    // const menuItems = user.type === 'InternalType' ? itemsInternal : itemsExternal;
    let menuItems = [];
    if (user.type === 'ExternalType' && user.group === 'Cashier') {
      menuItems = cashier;
    } else if (user.type === 'ExternalType' && user.group === 'Branch Admin') {
      menuItems = branchAdmin;
    } else if (user.type === 'ExternalType' && user.group === 'Merchant Admin') {
      menuItems = itemsExternal;
    } else if (user.type === 'ExternalType' && user.group === 'Accountant') {
      menuItems = accountant;
    } else {
      menuItems = itemsInternal;
    }
    // eslint-disable-next-line max-len
    // const updatedMenuItems = merchantData?.serviceBookingAllowed ? menuItems : menuItems.filter((menuItem) => menuItem.title !== 'Bookings');

    const filterMenuItems = () => {
      // Start with the full menuItems list
      // eslint-disable-next-line no-shadow
      let updatedMenuItems = menuItems;
      // Filter out 'Bookings' if serviceBookingAllowed is false
      if (!merchantData?.serviceBookingAllowed) {
        updatedMenuItems = updatedMenuItems.filter((menuItem) => menuItem.title !== 'Bookings');
      }
      if (!merchantData?.integratedWithETIMS) {
        updatedMenuItems = updatedMenuItems.map((menuItem) => {
          // Check if there are sub-menu items
          if (menuItem.items) {
            // Filter out sub-menu items that start with 'Etims'
            const filteredSubMenuItems = menuItem.items.filter(
              (subMenuItem) => !subMenuItem.title.startsWith('Etims ')
            );
            // Return the menuItem with filtered sub-menu items
            return { ...menuItem, items: filteredSubMenuItems };
          }
          // If no sub-menu items, return the menuItem as it is
          return menuItem;
        });
      }

      if (merchantData?.id !== 144) { // Check of merchantID is 144
        updatedMenuItems = updatedMenuItems.map((menuItem) => {
          // Check if there are sub-menu items
          if (menuItem.items) {
            // Filter out sub-menu items that start with 'Etims'
            const filteredSubMenuItems = menuItem.items.filter(
              (subMenuItem) => !subMenuItem.title.startsWith('PFMS')
            );
            // Return the menuItem with filtered sub-menu items
            return { ...menuItem, items: filteredSubMenuItems };
          }
          // If no sub-menu items, return the menuItem as it is
          return menuItem;
        });
      }
      return updatedMenuItems;
    };
    // Filter out 'Billing' if billingEnabled is false
    const finalMenuItems = filterMenuItems();
    setMenu(finalMenuItems);
    finalMenuItems.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname, merchantData]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#2B2F3C',
          color: '#B2B7C8',
          display: 'flex',
          flexDirection: 'column',
          top: 64,
          maxHeight: 'calc(100% - 64px)',
          width: '100vw'
        }
      }}
    >
      <List>
        {/* {user.type === 'InternalType' ? (
          activeItem && (itemsInternal.map((item) => (
            <DashboardNavbarMenuItem
              active={activeItem?.title === item.title}
              activeHref={activeHref}
              key={item.title}
              onClose={onClose}
              onOpenItem={() => handleOpenItem(item)}
              open={openedItem?.title === item.title}
              {...item}
            />
          )))
        ) : (
          activeItem && (itemsExternal.map((item) => (
            <DashboardNavbarMenuItem
              active={activeItem?.title === item.title}
              activeHref={activeHref}
              key={item.title}
              onClose={onClose}
              onOpenItem={() => handleOpenItem(item)}
              open={openedItem?.title === item.title}
              {...item}
            />
          )))
        )} */}
        {activeItem && (menu.map((item) => (
          <DashboardNavbarMenuItem
            active={activeItem?.title === item.title}
            activeHref={activeHref}
            badge={item.badge}
            key={item.title}
            onClose={onClose}
            onOpenItem={() => handleOpenItem(item)}
            open={openedItem?.title === item.title}
            {...item}
          />
        )))}
        {/* {activeItem && (items.map((item) => (
          <DashboardNavbarMenuItem
            active={activeItem?.title === item.title}
            activeHref={activeHref}
            key={item.title}
            onClose={onClose}
            onOpenItem={() => handleOpenItem(item)}
            open={openedItem?.title === item.title}
            {...item}
          />
        )))} */}
      </List>
    </Drawer>
  );
};

DashboardNavbarMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
