/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import jwtAxios from '../../api/jwt-api';
import { handleErrors } from '../error-service';

export const endpoints = {
  packagingUnits: 'packaging-units',
  productTypes: 'product-types',
  itemClassificationCodes: 'item-classification-codes',
  unitsOfQuantity: 'units-of-quantity',
  etimsTransactionStatuses: 'transaction-progress-statuses',
  getEtimsBranches: 'select-bhf-list',
  etimsImportsStatuses: 'import-item-statuses',
  merchantDeviceInfo: 'get-merchant-etims-details',
  refreshEtimsCodes: ''
};

const refreshFromEtims = window.localStorage.getItem('etimsLiveSync') === 'true';

export const etimsCodesParams = {
  TaxationTypes: 'Taxation Types',
  Countries: 'Countries',
  PaymentTypes: 'Payment Types',
  BranchStatuses: 'Branch Statuses',
  QuantityUnits: 'Quantity Units',
  SaleStatuses: 'Sale Statuses',
  StockMovements: 'Stock Movements',
  TransactionTypes: 'Transaction Types',
  PackingUnits: 'Packing Units',
  ItemTypes: 'Item Types',
  ImportItemStatuses: 'Import Item Statuses',
  RefundReasons: 'Refund Reasons',
  Currencies: 'Currencies',
  TransactionProgressStatuses: 'Transaction Progress Statuses',
  InventoryAdjustmentReasons: 'Inventory Adjustment Reasons',
  SalesReceiptTypes: 'Sales Receipt Types',
  PurchaseReceiptTypes: 'Purchase Receipt Types',
};

class EtimsService {
  constructor(merchantId, branchId) {
    this.merchantId = merchantId;
    this.branchId = branchId;
    this.defaultController = { pageNo: 0, pageSize: 1000 };
  }

  async getEtimsCodes(codeClassName = null, code = null, controller = this.defaultController) {
    try {
      const response = await jwtAxios.get(`/v1/etims/codes?codeClassName=${codeClassName}&code=${code}&pageNo=${controller.pageNo}&pageSize=${controller.pageSize}`);
      return response.data.data;
    } catch (error) {
      handleErrors(error, 'Etims codes');
      return Promise.reject(error);
    }
  }

  async getData(endpoint, code = null) {
    const param = code ? `?code=${code}` : '?';
    try {
      const response = await jwtAxios.get(`/v1/etims-code/${endpoint}${param}&branchId=${this.branchId}&refreshFromEtims=${refreshFromEtims}`);
      return response.data.data;
    } catch (error) {
      handleErrors(error, 'Etims codes');
      return Promise.reject(error);
    }
  }

  async getDataWithRefresh(endpoint, controller = this.defaultController) {
    try {
      const response = await jwtAxios.get(
        `/v1/etims/${endpoint}?refreshFromEtims=${refreshFromEtims}&pageNo=${controller.pageNo}&pageSize=${controller.pageSize}&branchId=${this.branchId}&merchantId=${this.merchantId}`
      );
      return response.data.data;
    } catch (error) {
      console.log('error', error);
      return Promise.reject(error);
    }
  }

  async getDataWithMerchantBranch(endpoint) {
    try {
      const response = await jwtAxios.get(
        `/v1/etims/${endpoint}?merchantId=${this.merchantId}&branchId=${this.branchId}`
      );
      return response.data.data;
    } catch (error) {
      console.log('error', error);
      return Promise.reject(error);
    }
  }

  // Add all other methods similarly
  async getPackagingUnits(code = null, controller = this.defaultController) {
    return this.getEtimsCodes(etimsCodesParams.PackingUnits, code, controller);
  }

  async postEtimsAction(endpoint) {
    try {
      const response = await jwtAxios.post(`/v1/etims/${endpoint}?branchId=${this.branchId}&refreshFromEtims=${refreshFromEtims}`);
      return response.data.data;
    } catch (error) {
      console.log('error', error);
      return Promise.reject(error);
    }
  }

  async refreshEtimsCodeAction() {
    return this.postEtimsAction('codes');
  }

  async getBranchInsuranceList(controller = this.defaultController) {
    return this.getDataWithRefresh('branch-insurances', controller);
  }

  async getEtimsDeviceInfo() {
    return this.getDataWithMerchantBranch(endpoints.merchantDeviceInfo);
  }
}

export default EtimsService;
