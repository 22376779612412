import { useEffect, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import dotenv from 'dotenv';
import { initializeI18n } from './i18n';
import { RTL } from './components/rtl';
import { faviconConfig, gtmConfig } from './config';
import { useSettings } from './contexts/settings-context';
import { useAuth } from './hooks/use-auth';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import { useMerchantContext } from './contexts/merchant-context';

export const App = () => {
  const { merchantData } = useMerchantContext();

  const filteredRoutes = useMemo(() => {
    if (merchantData?.serviceBookingAllowed === false) {
      const newRoutes = [...routes];
      newRoutes[0] = {
        ...newRoutes[0],
        children: newRoutes[0].children.filter((route) => route.path !== 'bookings') // Remove book
      };
      return newRoutes;
    }
    return routes;
  }, [merchantData?.serviceBookingAllowed]);

  const content = useRoutes(filteredRoutes);
  const { settings } = useSettings();
  const { isInitialized } = useAuth();

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.href = faviconConfig.favicon;
  }, []);

  useEffect(() => {
    gtm.initialize(gtmConfig);
    initializeI18n(settings.language);
  }, [settings]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        {isInitialized && content}
      </RTL>
    </ThemeProvider>
  );
};
