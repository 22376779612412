/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useError } from '../contexts/error-context';
import { CustomErrorCodesEnum } from '../utils/error-code-enum';

let hasErrorBeenShown = false;
export const handleErrors = (error, entity = 'Resource') => {
  const { showError } = useError();
  const navigate = useNavigate();
  if (error?.response) {
    const errorCode = error.response.data.error[0].code;
    const errorMessage = error.response.data.error[0].description;
    console.log('Error code: ', errorCode);
    if (error?.response.data && error.response.data.error_message) {
      console.log(error?.response.data.error_message);
      toast.error(`Error while handling ${entity}. Report this incident to us for assistance.`);
    } else if (
      error.response.data
      && error.response.data.error
      && error.response.data?.error[0]?.description
      && !errorCode >= 2000 && !errorCode < 2000
    ) {
      console.log(error?.response.data.error[0].description);
      toast.error(`${error?.response.data.error[0].description}. An error occurred while handling ${entity}. `);
    } else {
      console.log('Unknown error occurred.');
      toast.error(`Error handling ${entity}: Please try again later.`);
    }
  } else if (!hasErrorBeenShown) {
    hasErrorBeenShown = true;
    console.log('handleApiRequestError.', error);
    toast.error('Unknown error occurred. Please try again later.');
  } else {
    // call the error dialog
    toast.error('Oops! There must be an issue with your network because the issue is re-occurring, we will have to log you out for security reasons. Please login again.');
    // showError('Oops! There must be an issue with your network because the issue is re-occurring, we will have to log you out for security reasons. Please login again.', 'Error', () => {
    //   window.localStorage.removeItem('user');
    //   navigate('/login');
    // });
    // add a delay to allow the toast to show before redirecting
    // setTimeout(() => {
    //   window.localStorage.removeItem('user');
    //   window.location.href = '/login';
    // }, 5000);
  }
};

export const useHandleErrors = () => {
  const { showError } = useError();
  const navigate = useNavigate();
  const [hasErrorBeenShown, setHasErrorBeenShown] = useState(false);

  const handleErrors = useCallback((error, entity = 'Resource') => {
    if (error?.response) {
      const errorCode = error.response.data.error[0].code;
      const errorMessage = error.response.data.error[0].description;
      console.log('Error code: ', errorCode);
      if (errorCode >= 1000 && errorCode < 2000) {
        if (!hasErrorBeenShown) {
          setHasErrorBeenShown(true);
          showError(`${CustomErrorCodesEnum[errorCode]}.`,
            400,
            () => {
              navigate('/billing');
            }, 'Pay Now');
        }
      } else if (error?.response.data && error.response.data.error_message) {
        console.log(error?.response.data.error_message);
        toast.error(`Error while handling ${entity}. Report this incident to us for assistance.`);
      } else if (
        error.response.data
        && error.response.data.error
        && error.response.data?.error[0]?.description
        && !(errorCode >= 1000 && errorCode < 2000)
      ) {
        console.log(error?.response.data.error[0].description);
        toast.error(`${error?.response.data.error[0].description}. An error occurred while handling ${entity}.`);
      }
    } else {
      console.log('Unknown error occurred. ', error);
      toast.error(`An unknown error occurred while handling ${entity}. ${error?.message}`);
    }
  }, [hasErrorBeenShown, navigate, showError]);

  return handleErrors;
};
