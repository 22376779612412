/* eslint-disable max-len */
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { RoleBasedGuard } from './components/role-based-guard';
import { LoadingScreen } from './components/loading-screen';
import { Account } from './containers/account';
import { DashboardLayout } from './containers/dashboard-layout';
// import { Organization } from './containers/organization';
// import { Product } from './containers/product';
import { Merchant } from './containers/merchant';
import { MerchantAdmin } from './containers/merchant-admin';
import { Reports } from './containers/reports';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// account susspended page
const SuspendedAccountPage = Loadable(lazy(() => import('./containers/suspended-account-page').then((module) => ({ default: module.SuspendedAccount }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))));
const PasswordSetup = Loadable(lazy(() => import('./components/auth/password-setup').then((module) => ({ default: module.PasswordSetup }))));
const Register = Loadable(lazy(() => import('./containers/register').then((module) => ({ default: module.Register }))));
// customer waiver
const CustomerWaiver = Loadable(lazy(() => import('./containers/customer-waiver').then((module) => ({ default: module.CustomerWaiver }))));
const VerifyCode = Loadable(lazy(() => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode }))));

// Dashboard pages
const ReportsOverview = Loadable(lazy(() => import('./containers/reports-overview').then((module) => ({ default: module.ReportsOverview }))));
// const ReportsSales = Loadable(lazy(() => import('./containers/reports-sales').then((module) => ({ default: module.ReportsSales }))));

// const Customers = Loadable(lazy(() => import('./containers/customers').then((module) => ({ default: module.Customers }))));
const Users = Loadable(lazy(() => import('./containers/users').then((module) => ({ default: module.Users }))));
const UserGroup = Loadable(lazy(() => import('./containers/user-group').then((module) => ({ default: module.UserGroup }))));
// const CustomerActivity = Loadable(lazy(() => import('./containers/customer-activity').then((module) => ({ default: module.CustomerActivity }))));
const CustomerSalesHistory = Loadable(lazy(() => import('./containers/customer-sales-history').then((module) => ({ default: module.CustomerSalesHistory }))));
const CustomerSummary = Loadable(lazy(() => import('./containers/customer-summary').then((module) => ({ default: module.CustomerSummary }))));
const BulkMessage = Loadable(lazy(() => import('./containers/merchant-bulk-message').then((module) => ({ default: module.CustomersBulkMessage }))));
const Customer = Loadable(lazy(() => import('./containers/customer').then((module) => ({ default: module.Customer }))));
const EngageCustomer = Loadable(lazy(() => import('./containers/engage-customer').then((module) => ({ default: module.EngageCustomer }))));
const CustomerWishList = Loadable(lazy(() => import('./containers/customer-wish-list').then((module) => ({ default: module.CustomerWishList }))));

const Merchants = Loadable(lazy(() => import('./containers/merchants').then((module) => ({ default: module.Merchants }))));
// merchants billings
const MerchantsBilling = Loadable(lazy(() => import('./containers/merchants-billing').then((module) => ({ default: module.MerchantsBilling }))));
const MerchantsBillingBills = Loadable(lazy(() => import('./containers/merchants-billing-bills').then((module) => ({ default: module.MerchantsBillingBills }))));
const MerchantsBillingCategories = Loadable(lazy(() => import('./containers/merchants-billing-categories').then((module) => ({ default: module.MerchantsBillingCategories }))));
const MerchantsBillingInvoices = Loadable(lazy(() => import('./containers/merchants-billing-invoices').then((module) => ({ default: module.MerchantsBillingInvoices }))));
const MerchantsBillingTransactions = Loadable(lazy(() => import('./containers/merchants-billing-transactions').then((module) => ({ default: module.MerchantsBillingTransactions }))));
const MerchantsBillingAccounts = Loadable(lazy(() => import('./containers/merchants-billing-accounts').then((module) => ({ default: module.MerchantsBillingAccounts }))));

const MerchantSummary = Loadable(lazy(() => import('./containers/merchant-summary').then((module) => ({ default: module.MerchantSummary }))));
const MerchantBillingSummary = Loadable(lazy(() => import('./containers/merchant-billing-summary').then((module) => ({ default: module.MerchantBillingSummary }))));
const MerchantBranches = Loadable(lazy(() => import('./containers/merchant-branches').then((module) => ({ default: module.MerchantBranches }))));
const MerchantEtimsBranches = Loadable(lazy(() => import('./containers/merchant-etims-branches').then((module) => ({ default: module.MerchantEtimsBranches }))));
const MerchantEtimsInsurance = Loadable(lazy(() => import('./containers/etims/merchant-etims-insurance-list').then((module) => ({ default: module.EtimsInsuranceList }))));
const MerchantUsers = Loadable(lazy(() => import('./containers/merchant-users').then((module) => ({ default: module.MerchantUsers }))));
const MerchantUserGroup = Loadable(lazy(() => import('./containers/merchant-user-group').then((module) => ({ default: module.MerchantUserGroup }))));
const MerchantBrands = Loadable(lazy(() => import('./containers/merchant-brands').then((module) => ({ default: module.MerchantBrands }))));
const MerchantUnits = Loadable(lazy(() => import('./containers/merchant-units').then((module) => ({ default: module.MerchantUnits }))));
// Merchant Tax Rates Settings
const MerchantTaxRate = Loadable(lazy(() => import('./containers/merchant-tax-rate').then((module) => ({ default: module.MerchantTaxRate }))));
const MerchantEtimsTaxRate = Loadable(lazy(() => import('./components/merchant/merchant-tax/etims-taxes').then((module) => ({ default: module.EtimsTaxes }))));
const MerchantTimsTaxRate = Loadable(lazy(() => import('./components/merchant/merchant-tax/tims-taxes').then((module) => ({ default: module.TimsTaxes }))));
const MerchantCustomTaxRate = Loadable(lazy(() => import('./components/merchant/merchant-tax/custom-taxes').then((module) => ({ default: module.CustomTaxes }))));
const EtimsClassCodesTable = Loadable(lazy(() => import('./components/merchant/merchant-tax/etims-codes/etims-class-codes-table').then((module) => ({ default: module.EtimsClassCodesTable }))));
const EtimsItemsCodesTable = Loadable(lazy(() => import('./components/merchant/merchant-tax/etims-codes/etims-items-codes-table').then((module) => ({ default: module.EtimsItemsCodesTable }))));
const EtimsCodes = Loadable(lazy(() => import('./components/merchant/merchant-tax/etims-codes/etims-codes').then((module) => ({ default: module.EtimsCodes }))));

const MerchantVariations = Loadable(lazy(() => import('./containers/merchant-variation').then((module) => ({ default: module.MerchantVariations }))));
const MerchantCategory = Loadable(lazy(() => import('./containers/merchant-category').then((module) => ({ default: module.MerchantCategory }))));
const MerchantProduct = Loadable(lazy(() => import('./containers/merchant-product').then((module) => ({ default: module.MerchantProduct }))));
const MerchantProductDetail = Loadable(lazy(() => import('./containers/merchant-product-detail').then((module) => ({ default: module.MerchantProductDetail }))));
const MerchantProductAudit = Loadable(lazy(() => import('./containers/merchant-product-audit').then((module) => ({ default: module.MerchantProductAudit }))));
const MerchantProductAdd = Loadable(lazy(() => import('./containers/merchant-product-add').then((module) => ({ default: module.MerchantProductAdd }))));
const MerchantSupplier = Loadable(lazy(() => import('./containers/merchant-supplier').then((module) => ({ default: module.MerchantSupplier }))));
const MerchantPurchase = Loadable(lazy(() => import('./containers/merchant-purchase').then((module) => ({ default: module.MerchantPurchase }))));

// #Etims Routes
const MerchantETIMsList = Loadable(lazy(() => import('./components/etims-purchases/merchant-etims-purchases-list').then((module) => ({ default: module.MerchantEtimsPurchasesList }))));
const MerchantETIMs = Loadable(lazy(() => import('./containers/etims/merchant-etims-purchases').then((module) => ({ default: module.MerchantEtimsPurchases }))));
const MerchantETIMsReceive = Loadable(lazy(() => import('./containers/etims/merchant-etims-purchases-receive').then((module) => ({ default: module.MerchantEtimsPurchaseReceive }))));
const MerchantETIMsAdd = Loadable(lazy(() => import('./components/etims-purchases/merchant-etims-purchases-list').then((module) => ({ default: module.MerchantEtimsPurchasesAdd }))));
const MerchantETIMsImports = Loadable(lazy(() => import('./components/etims-purchases/etims-imports/merchant-etims-purchases-imports').then((module) => ({ default: module.MerchantEtimsPurchasesImports }))));
const MerchantEtimsInfo = Loadable(lazy(() => import('./components/merchant/merchant-tax/etims-info/etims-taxes-info').then((module) => ({ default: module.EtimsTaxesInfo }))));
const MerchantEtimsMoveList = Loadable(lazy(() => import('./containers/etims/merchant-etims-move-list').then((module) => ({ default: module.MerchantEtimsMoveList }))));
const MerchantEtimsItemList = Loadable(lazy(() => import('./containers/etims/merchant-etims-item-list').then((module) => ({ default: module.MerchantEtimsItemList }))));
const PurchaseReturn = Loadable(lazy(() => import('./containers/merchant-purchase-return').then((module) => ({ default: module.PurchaseReturn }))));
const MerchantCustomers = Loadable(lazy(() => import('./containers/merchant-customers').then((module) => ({ default: module.MerchantCustomers }))));
const MerchantCustomersCategories = Loadable(lazy(() => import('./containers/merchant-customers-categories').then((module) => ({ default: module.MerchantCustomersCategories }))));
const MerchantDiscounts = Loadable(lazy(() => import('./containers/merchant-discount').then((module) => ({ default: module.MerchantDiscounts }))));
const MerchantStockTransfer = Loadable(lazy(() => import('./containers/merchant-stock-transfer').then((module) => ({ default: module.MerchantStockTransfer }))));
const MerchantStockUpdate = Loadable(lazy(() => import('./containers/merchant-stock-update').then((module) => ({ default: module.MerchantStockUpdate }))));
const MerchantPayments = Loadable(lazy(() => import('./containers/merchant-payments').then((module) => ({ default: module.MerchantPayments }))));
const MerchantSales = Loadable(lazy(() => import('./containers/merchant-sales').then((module) => ({ default: module.MerchantSales }))));
const PosContent = Loadable(lazy(() => import('./containers/pos-content').then((module) => ({ default: module.PosContent }))));
const MerchantSalesHistory = Loadable(lazy(() => import('./containers/merchant-sales-history').then((module) => ({ default: module.MerchantSalesHistory }))));
const MerchantRecentSales = Loadable(lazy(() => import('./containers/merchant-recent-sales').then((module) => ({ default: module.MerchantRecentSales }))));
const MerchantSalesSuspended = Loadable(lazy(() => import('./containers/merchant-sales-suspended').then((module) => ({ default: module.MerchantSalesSuspended }))));
const MerchantSalesReserved = Loadable(lazy(() => import('./containers/merchant-sales-reserved').then((module) => ({ default: module.MerchantSalesReserved }))));
const MerchantSalesHistoryDetail = Loadable(lazy(() => import('./containers/merchant-sales-history-detail').then((module) => ({ default: module.MerchantSalesHistoryDetail }))));
const MerchantRecentSalesDetail = Loadable(lazy(() => import('./containers/merchant-recent-detail').then((module) => ({ default: module.MerchantRecentSalesDetail }))));
const MerchantSalesSuspendedDetail = Loadable(lazy(() => import('./containers/merchant-sales-suspended-detail').then((module) => ({ default: module.MerchantSalesSuspendedDetail }))));
const MerchantSalesReservedDetail = Loadable(lazy(() => import('./containers/merchant-sales-reserved-detail').then((module) => ({ default: module.MerchantSalesReservedDetail }))));
const MerchantSaleExchange = Loadable(lazy(() => import('./containers/merchant-sales-exchange').then((module) => ({ default: module.MerchantSaleExchange }))));
const MerchantExchangeDetail = Loadable(lazy(() => import('./containers/merchant-sales-exchange-detail').then((module) => ({ default: module.MerchantExchangeDetail }))));
const AddSalesExchange = Loadable(lazy(() => import('./containers/merchant-sales-exchange-add').then((module) => ({ default: module.AddSalesExchange }))));
const MerchantSaleReturn = Loadable(lazy(() => import('./containers/merchant-sales-return').then((module) => ({ default: module.MerchantSaleReturn }))));
const MerchantReturnDetail = Loadable(lazy(() => import('./containers/merchant-sales-return-detail').then((module) => ({ default: module.MerchantReturnDetail }))));
const MerchantSalesExchangeReturnReason = Loadable(lazy(() => import('./containers/merchant-sales-exchange-return-reasons').then((module) => ({ default: module.MerchantSalesExchangeReturnReason }))));
const MerchantRegister = Loadable(lazy(() => import('./containers/merchant-register').then((module) => ({ default: module.MerchantRegister }))));
const MerchantOpeningStock = Loadable(lazy(() => import('./containers/merchant-opening-stock').then((module) => ({ default: module.MerchantOpeningStock }))));
const MerchantImportProducts = Loadable(lazy(() => import('./containers/merchant-import-products').then((module) => ({ default: module.MerchantImportProducts }))));
const MerchantImportCustomers = Loadable(lazy(() => import('./containers/merchant-import-customers').then((module) => ({ default: module.MerchantImportCustomers }))));
const Product = Loadable(lazy(() => import('./containers/product').then((module) => ({ default: module.Product }))));
const ProductSummary = Loadable(lazy(() => import('./containers/product-summary').then((module) => ({ default: module.ProductSummary }))));
const MerchantShiftConfiguration = Loadable(lazy(() => import('./containers/merchant-shift-configuration').then((module) => ({ default: module.MerchantShiftConfiguration }))));
const MerchantAdditionalDiscountConfiguration = Loadable(lazy(() => import('./containers/merchant-additional-discount-configuration').then((module) => ({ default: module.MerchantAdditionalDiscountConfiguration }))));
const MerchantSalesReport = Loadable(lazy(() => import('./containers/merchant-sales-report').then((module) => ({ default: module.MerchantSalesReport }))));
const MerchantItemsSoldReport = Loadable(lazy(() => import('./containers/merchant-items-sold-report').then((module) => ({ default: module.MerchantItemsSoldReport }))));
const MerchantStockReport = Loadable(lazy(() => import('./containers/merchant-stock-report').then((module) => ({ default: module.MerchantStockReport }))));
const MerchantStockTransferReport = Loadable(lazy(() => import('./containers/merchant-stock-transfer-report').then((module) => ({ default: module.MerchantStockTransferReport }))));
const OutgoingStockTransferReport = Loadable(lazy(() => import('./containers/outgoing-stock-transfer-report').then((module) => ({ default: module.OutgoingStockTransferReport }))));
const OpeningClosingStockReport = Loadable(lazy(() => import('./containers/opening-closing-stock-report').then((module) => ({ default: module.OpeningClosingStockReport }))));
const MerchantProductActivityReport = Loadable(lazy(() => import('./containers/merchant-product-activity-report').then((module) => ({ default: module.MerchantProductActivityReport }))));
const MerchantPaymentConfiguration = Loadable(lazy(() => import('./containers/merchant-payment-configuration').then((module) => ({ default: module.MerchantPaymentConfiguration }))));
const MerchantPurchaseDetail = Loadable(lazy(() => import('./containers/merchant-purchase-detail').then((module) => ({ default: module.MerchantPurchaseDetail }))));
const MerchantReceiptConfiguration = Loadable(lazy(() => import('./containers/merchant-receipt-configuration').then((module) => ({ default: module.MerchantReceiptConfiguration }))));
const MerchantStockListing = Loadable(lazy(() => import('./containers/merchant-stock-listing').then((module) => ({ default: module.MerchantStockListing }))));
const MerchantSalesOverview = Loadable(lazy(() => import('./containers/merchant-sales-overview').then((module) => ({ default: module.MerchantSalesOverview }))));
const MerchantPrintLabels = Loadable(lazy(() => import('./containers/merchant-print-labels').then((module) => ({ default: module.MerchantPrintLabels }))));
const MerchantSalesPayments = Loadable(lazy(() => import('./containers/merchant-sales-payments').then((module) => ({ default: module.MerchantSalesPayments }))));
const MerchantMatchedSalesPayments = Loadable(lazy(() => import('./containers/merchant-matched-sales-payments').then((module) => ({ default: module.MerchantMatchedSalesPayments }))));
const MerchantUnmatchedSalesPayments = Loadable(lazy(() => import('./containers/merchant-unmatched-sales-payments').then((module) => ({ default: module.MerchantUnmatchedSalesPayments }))));
const MerchantWoocommerceIntegration = Loadable(lazy(() => import('./containers/merchant-woocommerce-integration').then((module) => ({ default: module.MerchantWoocommerceIntegration }))));
const MerchantWoocommerceOrders = Loadable(lazy(() => import('./containers/merchant-woocommerce-orders').then((module) => ({ default: module.MerchantWoocommerceOrders }))));
const MerchantStockAdjustment = Loadable(lazy(() => import('./containers/merchant-stock-adjustment').then((module) => ({ default: module.MerchantStockAdjustment }))));
const MerchantStockAdjustmentList = Loadable(lazy(() => import('./containers/merchant-stock-adjustment-list').then((module) => ({ default: module.MerchantStockAdjustmentList }))));
const MerchantStockAdjustmentReasons = Loadable(lazy(() => import('./containers/merchant-stock-adjustment-reasons').then((module) => ({ default: module.MerchantStockAdjustmentReasons }))));
const MerchantPlugins = Loadable(lazy(() => import('./containers/merchant-plugins').then((module) => ({ default: module.MerchantPlugins }))));
const MerchantStockAdjustedDetails = Loadable(lazy(() => import('./containers/merchant-stock-adjusted-details').then((module) => ({ default: module.MerchantStockAdjustedDetails }))));
const MerchantExpenses = Loadable(lazy(() => import('./containers/merchant-expenses').then((module) => ({ default: module.MerchantExpenses }))));
const MerchantSalesExpenses = Loadable(lazy(() => import('./containers/merchant-sales-expenses').then((module) => ({ default: module.MerchantSalesExpenses }))));
const MerchantExpenseCategories = Loadable(lazy(() => import('./containers/merchant-expense-categories').then((module) => ({ default: module.MerchantExpenseCategories }))));
const MerchantSaleExpenseDetail = Loadable(lazy(() => import('./containers/merchant-sales-expense-detail').then((module) => ({ default: module.MerchantSaleExpenseDetail }))));
const MerchantExpenseDetail = Loadable(lazy(() => import('./containers/merchant-expense-detail').then((module) => ({ default: module.MerchantExpenseDetail }))));
const WoocommerceOrderDetails = Loadable(lazy(() => import('./containers/woocommerce-order-details').then((module) => ({ default: module.WoocommerceOrderDetails }))));
const MerchantPurchaseReturnDetails = Loadable(lazy(() => import('./containers/merchant-purchase-return-details').then((module) => ({ default: module.MerchantPurchaseReturnDetails }))));
const WoocommerceOverview = Loadable(lazy(() => import('./containers/woocommerce-overview').then((module) => ({ default: module.WoocommerceOverview }))));
const MerchantCashiersOverview = Loadable(lazy(() => import('./containers/merchant-cashiers-overview').then((module) => ({ default: module.MerchantCashiersOverview }))));
const AggregatedSalesReport = Loadable(lazy(() => import('./containers/aggregated-sales-report').then((module) => ({ default: module.AggregatedSalesReport }))));
const MerchantDeliveryZones = Loadable(lazy(() => import('./containers/merchant-delivery-zones').then((module) => ({ default: module.MerchantDeliveryZones }))));
const MerchantPosSettings = Loadable(lazy(() => import('./containers/merchant-pos-settings').then((module) => ({ default: module.MerchantPosSettings }))));
const MerchantBulkMessageSettings = Loadable(lazy(() => import('./containers/merchant-bulk-message-settings').then((module) => ({ default: module.MerchantBulkMessageSettings }))));
const CustomSaleOrderStatus = Loadable(lazy(() => import('./containers/merchant-sale-order-statuses-settings').then((module) => ({ default: module.CustomSaleOrderStatus }))));
const MerchantStockSettings = Loadable(lazy(() => import('./containers/merchant-stock-settings').then((module) => ({ default: module.MerchantStockSettings }))));
const MessageHistory = Loadable(lazy(() => import('./containers/merchant-message-history').then((module) => ({ default: module.MerchantBulkMessageHistory }))));
const CustomerMessageHistory = Loadable(lazy(() => import('./containers/customer-messages-history').then((module) => ({ default: module.CustomerMessageHistory }))));
const OutOfStockItemsReport = Loadable(lazy(() => import('./containers/out-of-stock-items-report').then((module) => ({ default: module.OutOfStockItemsReport }))));
const SalesReport = Loadable(lazy(() => import('./containers/sales-report').then((module) => ({ default: module.SalesReport }))));
const BranchSalesReport = Loadable(lazy(() => import('./containers/branch-sales-report').then((module) => ({ default: module.BranchSalesReport }))));
const DetailedSalesReport = Loadable(lazy(() => import('./containers/detailed-sales-report').then((module) => ({ default: module.DetailedSalesReport }))));
const GeneralDetailedSalesReport = Loadable(lazy(() => import('./containers/general-detailed-sales-report').then((module) => ({ default: module.GeneralDetailedSalesReport }))));
const MerchantSkuActivity = Loadable(lazy(() => import('./containers/merchant-sku-activity').then((module) => ({ default: module.MerchantSkuActivity }))));
const MerchantStaff = Loadable(lazy(() => import('./containers/merchant-staff').then((module) => ({ default: module.MerchantStaff }))));
const MerchantStaffDetail = Loadable(lazy(() => import('./containers/merchant-staff-detail').then((module) => ({ default: module.MerchantStaffDetail }))));
const MerchantStaffInfo = Loadable(lazy(() => import('./containers/merchant-staff-info').then((module) => ({ default: module.MerchantStaffInfo }))));
const MerchantStaffCommissions = Loadable(lazy(() => import('./containers/merchant-staff-commissions').then((module) => ({ default: module.MerchantStaffCommissions }))));
const MerchantExpenseReport = Loadable(lazy(() => import('./containers/merchant-expense-report').then((module) => ({ default: module.MerchantExpenseReport }))));
const MerchantPurchaseAdd = Loadable(lazy(() => import('./containers/merchant-purchase-add').then((module) => ({ default: module.MerchantPurchaseAdd }))));
const MerchantPurchaseEdit = Loadable(lazy(() => import('./containers/merchant-purchase-edit').then((module) => ({ default: module.MerchantPurchaseEdit }))));
const MerchantAllDeactivated = Loadable(lazy(() => import('./containers/merchant-all-deactivated').then((module) => ({ default: module.MerchantAllDeactivated }))));
const MerchantDeactivatedProducts = Loadable(lazy(() => import('./containers/merchant-deactivated-products').then((module) => ({ default: module.MerchantDeactivatedProducts }))));
const MerchantDeactivatedSkus = Loadable(lazy(() => import('./containers/merchant-deactivated-skus').then((module) => ({ default: module.MerchantDeactivatedSkus }))));
const MerchantProductsAll = Loadable(lazy(() => import('./containers/merchant-products-all').then((module) => ({ default: module.MerchantProductsAll }))));
const ProductCustomFields = Loadable(lazy(() => import('./containers/product-custom-fields').then((module) => ({ default: module.ProductCustomFields }))));
const MerchantAddProductService = Loadable(lazy(() => import('./containers/merchant-add-product-service').then((module) => ({ default: module.MerchantAddProductService }))));
const MerchantServicesList = Loadable(lazy(() => import('./containers/merchant-services-list').then((module) => ({ default: module.MerchantServicesList }))));
const MerchantServices = Loadable(lazy(() => import('./containers/merchant-services').then((module) => ({ default: module.MerchantServices }))));
const MerchantServiceEdit = Loadable(lazy(() => import('./containers/merchant-service-edit').then((module) => ({ default: module.MerchantServiceEdit }))));
const MerchantServiceSummary = Loadable(lazy(() => import('./containers/merchant-service-summary').then((module) => ({ default: module.MerchantServiceSummary }))));
const CustomerCustomFields = Loadable(lazy(() => import('./containers/customer-custom-fields').then((module) => ({ default: module.CustomerCustomFields }))));
const MerchantTaxOutputReport = Loadable(lazy(() => import('./containers/merchant-taxoutput-report').then((module) => ({ default: module.MerchantTaxOutputReport }))));
const MerchantTaxInputReport = Loadable(lazy(() => import('./containers/merchant-taxinput-report').then((module) => ({ default: module.MerchantTaxInputReport }))));
const MerchantExpensesByCategoryReport = Loadable(lazy(() => import('./containers/merchant-expenses-by-category-report').then((module) => ({ default: module.MerchantExpensesByCategoryReport }))));
const MerchantAllSalesReports = Loadable(lazy(() => import('./containers/merchant-all-sales-reports').then((module) => ({ default: module.MerchantAllSalesReports }))));
const MerchantAllStockReports = Loadable(lazy(() => import('./containers/merchant-all-stock-reports').then((module) => ({ default: module.MerchantAllStockReports }))));
const MerchantAllExpensesReports = Loadable(lazy(() => import('./containers/merchant-all-expenses-reports').then((module) => ({ default: module.MerchantAllExpensesReports }))));
const MerchantAllTaxReports = Loadable(lazy(() => import('./containers/merchant-all-tax-reports').then((module) => ({ default: module.MerchantAllTaxReports }))));
const MerchantNotificationSettings = Loadable(lazy(() => import('./containers/merchant-notification-settings').then((module) => ({ default: module.MerchantNotificationSettings }))));
const MerchantNotificationTemplates = Loadable(lazy(() => import('./containers/merchant-notification-templates').then((module) => ({ default: module.MerchantNotificationTemplates }))));
const MerchantNotificationTriggers = Loadable(lazy(() => import('./containers/merchant-notification-triggers').then((module) => ({ default: module.MerchantNotificationTriggers }))));
const MerchantOpeningClosingStockReport = Loadable(lazy(() => import('./containers/merchant-opening-closing-stock-report').then((module) => ({ default: module.MerchantOpeningClosingStockReport }))));
const MerchantClosingStockReport = Loadable(lazy(() => import('./containers/merchant-closing-stock-report').then((module) => ({ default: module.MerchantClosingStockReport }))));
const MerchantStandardReports = Loadable(lazy(() => import('./containers/merchant-standard-reports').then((module) => ({ default: module.MerchantStandardReports }))));
const MerchantStandardSalesReport = Loadable(lazy(() => import('./containers/merchant-standard-sales-report').then((module) => ({ default: module.MerchantStandardSalesReport }))));
const MerchantStockTakeReport = Loadable(lazy(() => import('./containers/merchant-stock-take-report').then((module) => ({ default: module.MerchantStockTakeReport }))));
const MerchantPurchaseReport = Loadable(lazy(() => import('./containers/merchant-purchase-report').then((module) => ({ default: module.MerchantPurchaseReport }))));
const MerchantPurchaseItemReport = Loadable(lazy(() => import('./containers/merchant-purchase-item-report').then((module) => ({ default: module.MerchantPurchaseItemReport }))));
const MerchantPurchaseDetailedReport = Loadable(lazy(() => import('./containers/merchant-purchase-detailed-report').then((module) => ({ default: module.MerchantPurchaseDetailedReport }))));
const MerchantIncomeReport = Loadable(lazy(() => import('./containers/merchant-income-report').then((module) => ({ default: module.MerchantIncomeReport }))));
const MerchantPaymentsReport = Loadable(lazy(() => import('./containers/merchant-payments-report').then((module) => ({ default: module.MerchantPaymentsReport }))));
const MerchantDirectStockReport = Loadable(lazy(() => import('./containers/merchant-direct-stock-report').then((module) => ({ default: module.MerchantDirectStockReport }))));
const MerchantServicesAll = Loadable(lazy(() => import('./containers/merchant-services-all').then((module) => ({ default: module.MerchantServicesAll }))));
const MerchantAllDeactivatedServices = Loadable(lazy(() => import('./containers/merchant-all-deactivated-services').then((module) => ({ default: module.MerchantAllDeactivatedServices }))));
const MerchantDeactivatedServices = Loadable(lazy(() => import('./containers/merchant-deactivated-services').then((module) => ({ default: module.MerchantDeactivatedServices }))));
const MerchantDeactivatedServiceSkus = Loadable(lazy(() => import('./containers/merchant-deactivated-service-skus').then((module) => ({ default: module.MerchantDeactivatedServiceSkus }))));
const MerchantHistoricalRegisterReport = Loadable(lazy(() => import('./containers/merchant-historical-registers-report').then((module) => ({ default: module.MerchantHistoricalRegistersReport }))));
const MerchantHistoricalRegisterSessionDetails = Loadable(lazy(() => import('./containers/merchant-historical-register-session-details').then((module) => ({ default: module.MerchantHistoricalRegisterSessionDetails }))));
const MerchantHistoricalRegisterShiftDetails = Loadable(lazy(() => import('./containers/merchant-historical-register-shift-details').then((module) => ({ default: module.MerchantHistoricalRegisterShiftDetails }))));
const Invoices = Loadable(lazy(() => import('./containers/invoices').then((module) => ({ default: module.Invoices }))));
const SaleInvoices = Loadable(lazy(() => import('./containers/create-sale-order-invoices').then((module) => ({ default: module.SaleInvoices }))));
const InvoiceCreate = Loadable(lazy(() => import('./containers/invoice-create').then((module) => ({ default: module.InvoiceCreate }))));
const InvoiceSummary = Loadable(lazy(() => import('./containers/invoice').then((module) => ({ default: module.Invoice }))));
const InvoicePreview = Loadable(lazy(() => import('./containers/invoice-preview').then((module) => ({ default: module.InvoicePreview }))));
const InvoiceSettings = Loadable(lazy(() => import('./containers/invoice-settings').then((module) => ({ default: module.InvoiceSettings }))));
const BranchStockTake = Loadable(lazy(() => import('./containers/merchant-stock-take').then((module) => ({ default: module.BranchStockTake }))));
const MerchantStockTakeSessions = Loadable(lazy(() => import('./containers/merchant-stock-take-sessions').then((module) => ({ default: module.MerchantStockTakeSessions }))));
// merchant billing routes
const MerchantBilling = Loadable(lazy(() => import('./containers/merchant-billing').then((module) => ({ default: module.MerchantBilling }))));
const MerchantBillingInvoice = Loadable(lazy(() => import('./containers/merchant-billing-invoice').then((module) => ({ default: module.BillingInvoice }))));
const MerchantBillingTransaction = Loadable(lazy(() => import('./containers/merchant-billing-transaction').then((module) => ({ default: module.MerchantBillingTransaction }))));
const MerchantPaymentConfirm = Loadable(lazy(() => import('./containers/merchant-payment-confirm').then((module) => ({ default: module.MerchantPaymentConfirm }))));
const MerchantStockTakeSessionDetails = Loadable(lazy(() => import('./containers/merchant-stock-take-session-details').then((module) => ({ default: module.MerchantStockTakeSessionDetails }))));
const MerchantProductsPriceList = Loadable(lazy(() => import('./containers/merchant-products-price-list').then((module) => ({ default: module.MerchantProductsPriceList }))));
const MerchantServicesPriceList = Loadable(lazy(() => import('./containers/merchant-services-price-list').then((module) => ({ default: module.MerchantServicesPriceList }))));
const MerchantAllPriceListReports = Loadable(lazy(() => import('./containers/merchant-all-price-list-reports').then((module) => ({ default: module.MerchantAllPriceListReports }))));
const MerchantBookingSettings = Loadable(lazy(() => import('./components/merchant/merchant-booking-settings').then((module) => ({ default: module.MerchantBookingSettings }))));
const MerchantBookingList = Loadable(lazy(() => import('./components/bookings/bookings-list').then((module) => ({ default: module.MerchantBookingList }))));
const MerchantSkuSalesCountReport = Loadable(lazy(() => import('./components/merchant-sku-sales-count').then((module) => ({ default: module.MerchantSkuSalesCountReport }))));
const SkuSalesItemsSummaryReports = Loadable(lazy(() => import('./containers/sku-sales-items-summary').then((module) => ({ default: module.SkuSalesItemsSummaryReports }))));
const CategorizedSkuSalesSummaryReport = Loadable(lazy(() => import('./components/categorized-skus-sales-summary').then((module) => ({ default: module.CategorizedSkuSalesSummaryReport }))));
const SubCategorizedSkuSalesSummaryReport = Loadable(lazy(() => import('./components/sub-categorized-skus-sales-summary').then((module) => ({ default: module.SubCategorizedSkuSalesSummaryReport }))));
const MerchantCalendarView = Loadable(lazy(() => import('./components/bookings/calendar/calendar').then((module) => ({ default: module.MerchantCalendarView }))));
const MerchantBannerSettings = Loadable(lazy(() => import('./components/merchant-banner-settings').then((module) => ({ default: module.MerchantBannerSettings }))));
const MerchantSubscriptionPlans = Loadable(lazy(() => import('./components/merchant/merchant-subscription-plans').then((module) => ({ default: module.MerchantSubscriptionPlans }))));
const CustomerSubscriptions = Loadable(lazy(() => import('./containers/customer-subscriptions').then((module) => ({ default: module.CustomerSubscriptions }))));
// BookingSummary module
const BookingSummary = Loadable(lazy(() => import('./components/bookings/booking-summary').then((module) => ({ default: module.BookingSummary }))));
// income statement
const IncomeStatement = Loadable(lazy(() => import('./containers/merchant-income-statement').then((module) => ({ default: module.IncomeStatementReportPage }))));
// const Products = Loadable(lazy(() => import('./containers/products').then((module) => ({ default: module.Products }))));
// const ProductAnalytics = Loadable(lazy(() => import('./containers/product-analytics').then((module) => ({ default: module.ProductAnalytics }))));
// const ProductInventory = Loadable(lazy(() => import('./containers/product-inventory').then((module) => ({ default: module.ProductInventory }))));
// const ProductSummary = Loadable(lazy(() => import('./containers/product-summary').then((module) => ({ default: module.ProductSummary }))));

// pfms
const MerchantPfmsStations = Loadable(lazy(() => import('./containers/merchant-pfms-stations').then((module) => ({ default: module.MerchantPfmsStations }))));
const PfmsStationsReports = Loadable(lazy(() => import('./containers/pfms-stations-reports').then((module) => ({ default: module.PfmsStationsReports }))));
const PfmsStationStockList = Loadable(lazy(() => import('./components/station-stock-list').then((module) => ({ default: module.PfmsStationStockList }))));
const DailyCashierSummaryList = Loadable(lazy(() => import('./components/reports/pmfs/daily-cashier-summary-list').then((module) => ({ default: module.DailyCashierSummaryList }))));
const DailyExpensePurchaseList = Loadable(lazy(() => import('./components/reports/pmfs/daily-expense-purchase-list').then((module) => ({ default: module.DailyExpensePurchaseList }))));
const PfmsDailyDebtorsAccountingPosition = Loadable(lazy(() => import('./components/daily-debtors-account-position').then((module) => ({ default: module.PfmsDailyDebtorsAccountingPosition }))));

const AccountGeneral = Loadable(lazy(() => import('./containers/account-general').then((module) => ({ default: module.AccountGeneral }))));
const AccountNotifications = Loadable(lazy(() => import('./containers/account-notifications').then((module) => ({ default: module.AccountNotifications }))));

const WcommerceOrdersTable = Loadable(lazy(() => import('./components/wcommerce/wcommerce-orders-table').then((module) => ({ default: module.WcommerceOrdersTable }))));

const roles = {
  superAdmin: 'Super Admin',
  merchantAdmin: 'Merchant Admin',
  branchAdmin: 'Branch Admin',
  cashier: 'Cashier',
  accountant: 'Accountant',
  waiverHandler: 'Waiver Handler',
};

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
            <Navigate
              to="/summary"
              replace
            />
          </RoleBasedGuard>
        )
      },
      {
        path: 'summary',
        element: <Reports />,
        children: [
          {
            path: '',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
                <MerchantSalesOverview />
              </RoleBasedGuard>
            )
          },
          {
            path: 'analytics',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.superAdmin, roles.accountant]}>
                <ReportsOverview />
              </RoleBasedGuard>
            )
          },
          {
            path: 'e-commerce',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
                <WoocommerceOverview />
              </RoleBasedGuard>
            )
          },
          {
            path: 'cashiers-performance',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
                <MerchantCashiersOverview />
              </RoleBasedGuard>
            )
          }
        ]
      },
      {
        path: 'account',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.superAdmin, roles.accountant, roles.cashier]}>
            <Account />
          </RoleBasedGuard>
        ),
        children: [
          {
            path: '',
            element: <AccountGeneral />
          },
          {
            path: 'notifications',
            element: <AccountNotifications />
          }
          // {
          //   path: 'team',
          //   element: <OrganizationTeam />
          // }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <Users />
          },
          {
            path: 'group',
            element: <UserGroup />
          }
        ]
      },
      {
        path: 'merchants',
        children: [
          {
            path: '',
            element: <Merchants />
          },
          {
            path: ':merchantId',
            element: <Merchant />,
            children: [
              {
                path: '',
                element: <MerchantSummary />
              },
              {
                path: 'billing',
                element: <MerchantBillingSummary />
              },
              {
                path: 'branches',
                element: <MerchantBranches />
              },
              {
                path: 'users',
                element: <MerchantUsers />
              },
              {
                path: 'user-group',
                element: <MerchantUserGroup />
              },
              {
                path: 'brands',
                element: <MerchantBrands />
              },
              {
                path: 'units',
                element: <MerchantUnits />
              },
              {
                path: 'tax-rate',
                element: <MerchantTaxRate />
              },
              {
                path: 'Variation',
                element: <MerchantVariations />
              },
              {
                path: 'category',
                element: <MerchantCategory />
              },
              {
                path: 'product',
                element: <MerchantProduct />
              },
              {
                path: 'product-add',
                element: <MerchantProductAdd />
              },
              {
                path: ':productId',
                element: <Product />,
                children: [
                  {
                    path: 'summary',
                    element: <ProductSummary />
                  },
                  {
                    path: 'update',
                    element: <MerchantProductDetail />
                  },
                  {
                    path: 'audit',
                    element: <MerchantProductAudit />
                  },
                  {
                    path: 'audit/:skuId',
                    element: <MerchantSkuActivity />
                  }
                ]
              },
              {
                path: 'supplier',
                element: <MerchantSupplier />
              },
              {
                path: 'purchase',
                element: <MerchantPurchase />
              },
              {
                path: 'purchase-return',
                element: <PurchaseReturn />
              },
              {
                path: 'customers',
                element: <MerchantCustomers />
              },
              {
                path: 'discounts',
                element: <MerchantDiscounts />
              },
              {
                path: 'stock-transfer',
                element: <MerchantStockTransfer />
              },
              {
                path: 'payments',
                element: <MerchantPayments />
              },
              {
                path: 'opening-stock',
                element: <MerchantOpeningStock />
              },
              {
                path: 'import-products',
                element: <MerchantImportProducts />
              },
              {
                path: 'import-customers',
                element: <MerchantImportCustomers />
              },
              {
                path: ':uniqueID',
                element: <MerchantProductActivityReport />
              },
              {
                path: 'notifications/templates',
                element: <MerchantNotificationTemplates />
              }
            ]
          },
          {
            path: 'billing',
            element: <MerchantsBilling />,
            children: [
              {
                path: 'invoices',
                element: <MerchantsBillingInvoices />
              },
              {
                path: 'bills',
                element: <MerchantsBillingBills />
              },
              {
                path: 'transactions',
                element: <MerchantsBillingTransactions />
              },
              {
                path: 'categories',
                element: <MerchantsBillingCategories />
              },
              {
                path: 'accounts',
                element: <MerchantsBillingAccounts />
              }

            ]
          }
        ]
      },
      {
        path: 'users',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.superAdmin]}>
            <MerchantAdmin />
          </RoleBasedGuard>
        ),
        children: [
          {
            path: 'list',
            element: <MerchantUsers />
          },
          {
            path: 'user/group',
            element: <MerchantUserGroup />
          }
        ]
      },
      {
        path: 'branches',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.superAdmin]}>
            <MerchantAdmin />
          </RoleBasedGuard>
        ),
        children: [
          {
            path: 'list',
            element: <MerchantBranches />
          },
          {
            path: 'etims-list',
            element: <MerchantEtimsBranches />
          }
        ]
      },
      {
        path: 'products',
        element: (
          <MerchantAdmin />
        ),
        children: [
          {
            path: 'list',
            element: <MerchantProductsAll />,
            children: [
              {
                path: '',
                element: (
                  <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                    <MerchantProduct />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'deactivated',
                element: <MerchantAllDeactivated />,
                children: [
                  {
                    path: '',
                    element: <MerchantDeactivatedProducts />
                  },
                  {
                    path: 'skus',
                    element: <MerchantDeactivatedSkus />
                  },
                ]
              },
            ]
          },
          {
            path: 'etims-item-list',
            element: <MerchantEtimsItemList />
          },
          {
            path: 'services',
            children: [
              {
                path: 'list',
                element: <MerchantServicesAll />,
                children: [
                  {
                    path: '',
                    element: <MerchantServicesList />,
                  },
                  {
                    path: 'deactivated',
                    element: <MerchantAllDeactivatedServices />,
                    children: [
                      {
                        path: '',
                        element: <MerchantDeactivatedServices />
                      },
                      {
                        path: 'skus',
                        element: <MerchantDeactivatedServiceSkus />
                      }
                    ]
                  }
                ]
              },
              {
                path: ':id',
                element: <MerchantServices />,
                children: [
                  {
                    path: 'summary',
                    element: <MerchantServiceSummary />
                  },
                  {
                    path: 'update',
                    element: <MerchantServiceEdit />
                  }
                ]
              }
            ]
          },
          {
            path: 'add',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantAddProductService />
              </RoleBasedGuard>
            )
          },
          {
            path: 'import-products',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantImportProducts />
              </RoleBasedGuard>
            )
          },
          {
            path: ':productId',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <Product />
              </RoleBasedGuard>
            ),
            children: [
              {
                path: 'summary',
                element: <ProductSummary />
              },
              {
                path: 'update',
                element: <MerchantProductDetail />
              },
              {
                path: 'audit',
                element: <MerchantProductAudit />
              },
              {
                path: 'audit/:skuId',
                element: <MerchantSkuActivity />
              }
            ]
          },
          {
            path: 'category',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantCategory />
              </RoleBasedGuard>
            )
          },
          {
            path: 'variations',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantVariations />
              </RoleBasedGuard>
            )
          },
          {
            path: 'units',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantUnits />
              </RoleBasedGuard>
            )
          },
          {
            path: 'brands',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantBrands />
              </RoleBasedGuard>
            )
          },
          {
            path: 'print-labels',
            element: <MerchantPrintLabels />
          },
          {
            path: 'custom-fields',
            element: <ProductCustomFields />
          }
          // {
          //   path: ':productId',
          //   element: <MerchantProduct />,
          //   children: [
          //     {
          //       index: true,,
          //       element: <ProductSummary />
          //     },
          //     {
          //       path: 'analytics',
          //       element: <ProductAnalytics />
          //     },
          //     {
          //       path: 'inventory',
          //       element: <ProductInventory />
          //     }
          //   ]
          // }
        ]
      },
      {
        path: '/products/price/list',
        element: <MerchantProductsPriceList />
      },
      {
        path: '/services/price/list',
        element: <MerchantServicesPriceList />
      },
      {
        path: 'purchase',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.accountant]}>
            <MerchantAdmin />
          </RoleBasedGuard>
        ),
        children: [
          {
            path: 'list',
            element: <MerchantPurchase />
          },
          {
            path: 'add',
            element: <MerchantPurchaseAdd />
          },
          {
            path: 'edit/:purchaseId',
            element: <MerchantPurchaseEdit />
          },
          {
            path: 'return',
            element: <PurchaseReturn />
          },
          {
            path: 'return/:id',
            element: <MerchantPurchaseReturnDetails />
          },
          {
            path: 'suppliers',
            element: <MerchantSupplier />
          },
          {
            path: 'payments',
            element: <MerchantPayments />
          },
          {
            path: 'etims',
            element: (
              <>
                <MerchantETIMs />
              </>
            ),
            children: [

              {
                path: 'list',
                element: <MerchantETIMsList />
              },
              {
                path: 'receive',
                element: <MerchantETIMsReceive />
              },
              {
                path: 'add',
                element: <MerchantETIMsAdd />
              },
              {
                path: 'imports',
                element: <MerchantETIMsImports />
              }
            ]
          },
          {
            path: 'receive',
            element: <MerchantETIMsReceive />
          },
          {
            path: ':purchaseId',
            children: [
              {
                path: 'summary',
                element: <MerchantPurchaseDetail />
              }
            ]
          },
        ]
      },
      {
        path: 'expenses',
        element: <MerchantAdmin />,
        children: [
          {
            path: 'list',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.accountant]}>
                <MerchantExpenses />
              </RoleBasedGuard>
            )
          },
          {
            path: ':expenseId',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.accountant]}>
                <MerchantExpenseDetail />
              </RoleBasedGuard>
            )
          },
          {
            path: 'categories',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantExpenseCategories />
              </RoleBasedGuard>
            )
          }
        ]
      },
      {
        path: 'stock-management',
        element: <MerchantAdmin />,
        children: [
          {
            path: 'branch-stock-monitoring',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.accountant]}>
                <MerchantStockListing />
              </RoleBasedGuard>
            )
          },
          {
            path: 'direct-stock-update',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.accountant]}>
                <MerchantStockUpdate />
              </RoleBasedGuard>
            )
          },
          {
            path: 'transfer',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.accountant]}>
                <MerchantStockTransfer />
              </RoleBasedGuard>
            )
          },
          {
            path: 'opening-stock',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantOpeningStock />
              </RoleBasedGuard>
            )
          },
          {
            path: 'stock-adjustment',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
                <MerchantStockAdjustment />
              </RoleBasedGuard>
            ),
            children: [
              {
                path: '',
                element: <MerchantStockAdjustmentList />
              },
              {
                path: 'reasons',
                element: <MerchantStockAdjustmentReasons />
              }
            ]
          },
          {
            path: 'stock-adjustment/:stockId',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
                <MerchantStockAdjustedDetails />
              </RoleBasedGuard>
            )
          },
          {
            path: '/stock-management/branch-stock-take',
            element: <MerchantStockTakeSessions />
          },
          {
            path: '/stock-management/branch-stock-take/:stockSessionId/edit',
            element: <BranchStockTake />
          },
          {
            path: '/stock-management/branch-stock-take/:stockSessionId/view',
            element: <MerchantStockTakeSessionDetails />
          },
          {
            path: 'etims-move-list',
            element: <MerchantEtimsMoveList />
          },
        ]
      },
      {
        path: 'pos',
        element: <MerchantAdmin />,
        children: [
          {
            path: 'sales',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin, roles.cashier]}>
                <MerchantSales />
              </RoleBasedGuard>
            ),
            children: [
              {
                path: '',
                element: <PosContent />
              },
              {
                path: 'recent',
                element: <MerchantRecentSales />,
              },
              {
                path: 'recent/:saleID',
                element: <MerchantRecentSalesDetail />,
              },
              {
                path: 'suspended',
                element: <MerchantSalesSuspended />,
              },
              {
                path: 'suspended/:saleID',
                element: <MerchantSalesSuspendedDetail />,
              },
              {
                path: 'reserved',
                element: <MerchantSalesReserved />,
              },
              {
                path: 'reserved/:saleID',
                element: <MerchantSalesReservedDetail />,
              },
              {
                path: 'exchange-return-reason',
                element: <MerchantSalesExchangeReturnReason />,
              },
              {
                path: 'return',
                element: <MerchantSaleReturn />,
              },
              {
                path: 'return/detail',
                element: <MerchantReturnDetail />,
              },
              {
                path: 'exchange',
                element: <MerchantSaleExchange />,
              },
              {
                path: 'exchange/detail',
                element: <MerchantExchangeDetail />,
              },
              {
                path: 'exchange/add',
                element: <AddSalesExchange />,
              },
              {
                path: 'expenses',
                element: <MerchantSalesExpenses />,
              },
              {
                path: 'expenses/detail',
                element: <MerchantSaleExpenseDetail />
              },
              {
                path: 'expenses/categories',
                element: <MerchantExpenseCategories />
              },
              {
                path: 'online-orders',
                element: <WcommerceOrdersTable />
              }
            ]
          },
          {
            path: 'sales-history',
            element: <MerchantSalesHistory />,
          },
          {
            path: 'sales-history/:saleID',
            element: <MerchantSalesHistoryDetail />,
          },
          {
            path: 'stock-report',
            element: <MerchantStockReport />,
            children: [
              {
                path: ':uniqueID',
                element: <MerchantProductActivityReport />
              }
            ]
          },
          {
            path: 'register',
            element: <MerchantRegister />
          },
          {
            path: 'sales-payments',
            element: <MerchantSalesPayments />,
            children: [
              {
                path: 'matched',
                element: <MerchantMatchedSalesPayments />
              },
              {
                path: 'unmatched',
                element: <MerchantUnmatchedSalesPayments />
              }
            ]
          },
          {
            path: 'sales-exchange',
            element: <MerchantSaleExchange />,
          },
          {
            path: 'sales-return',
            element: <MerchantSaleReturn />,
          },
          {
            path: 'sales-exchange-return-reason',
            element: <MerchantSalesExchangeReturnReason />,
          },
          {
            path: 'ecommerce-orders',
            element: <MerchantWoocommerceOrders />,
          },
          {
            path: 'ecommerce-orders/:orderId',
            element: <WoocommerceOrderDetails />
          },
        ]
      },
      {
        path: 'reports',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.accountant]}>
            <MerchantAdmin />
          </RoleBasedGuard>
        ),
        children: [
          {
            path: 'sales',
            element: <MerchantAllSalesReports />
          },
          {
            path: 'historical-registers-report',
            children: [
              {
                index: true,
                element: <MerchantHistoricalRegisterReport />
              },
              {
                path: 'session/:sessionId',
                element: <MerchantHistoricalRegisterSessionDetails />
              },
              {
                path: 'shift/:shiftId',
                element: <MerchantHistoricalRegisterShiftDetails />
              }
            ]
          },
          {
            path: 'sales/sales-report',
            element: <SalesReport />,
            children: [
              {
                path: '',
                element: <MerchantSalesReport />
              },
              {
                path: 'branch',
                element: <BranchSalesReport />
              },
              {
                path: 'detailed',
                element: <DetailedSalesReport />
              },
              {
                path: 'general-detailed',
                element: <GeneralDetailedSalesReport />
              }
            ]
          },
          {
            path: 'sales/items-summary',
            element: <SkuSalesItemsSummaryReports />,
            children: [
              {
                path: '',
                element: <MerchantSkuSalesCountReport />
              },
              {
                path: 'categorized',
                element: <CategorizedSkuSalesSummaryReport />
              },
              {
                path: 'sub-categorized',
                element: <SubCategorizedSkuSalesSummaryReport />
              },
            ]
          },
          {
            path: 'sales/aggregated-sales-report',
            element: <AggregatedSalesReport />
          },
          {
            path: 'sales/sale-items-report',
            element: <MerchantItemsSoldReport />
          },
          {
            path: 'stock',
            element: <MerchantAllStockReports />
          },
          {
            path: 'stock/stock-report',
            element: <MerchantStockReport />,
            children: [
              {
                path: ':uniqueID',
                element: <MerchantProductActivityReport />
              }
            ]
          },

          {
            path: 'stock/out-of-stock-report',
            element: <OutOfStockItemsReport />
          },
          {
            path: 'stock/incoming-stock-transfer-report',
            element: <MerchantStockTransferReport />
          },
          {
            path: 'stock/outgoing-stock-transfer-report',
            element: <OutgoingStockTransferReport />
          },
          {
            path: 'stock/opening-closing-stock-report',
            element: <OpeningClosingStockReport />
          },
          {
            path: 'expenses',
            element: <MerchantAllExpensesReports />
          },
          {
            path: 'expenses/expenses-report',
            element: <MerchantExpenseReport />
          },
          {
            path: 'expenses/expenses-by-category-report',
            element: <MerchantExpensesByCategoryReport />
          },
          {
            path: 'tax',
            element: <MerchantAllTaxReports />
          },
          {
            path: 'tax/tax-input-report',
            element: <MerchantTaxInputReport />
          },
          {
            path: 'tax/tax-output-report',
            element: <MerchantTaxOutputReport />
          },
          {
            path: 'standard-reports',
            element: <MerchantStandardReports />
          },
          {
            path: 'standard-reports/closing-stock-report',
            element: <MerchantClosingStockReport />
          },
          {
            path: 'standard-reports/stock-take-report',
            element: <MerchantStockTakeReport />
          },
          {
            path: 'standard-reports/purchases-report',
            element: <MerchantPurchaseReport />
          },
          {
            path: 'standard-reports/purchase-item-report',
            element: <MerchantPurchaseItemReport />
          },
          {
            path: 'standard-reports/income-report',
            element: <MerchantIncomeReport />
          },
          {
            path: 'standard-reports/direct-stock-update-report',
            element: <MerchantDirectStockReport />
          },
          {
            path: 'standard-reports/purchase-detailed-report',
            element: <MerchantPurchaseDetailedReport />
          },
          {
            path: 'standard-reports/sales-report',
            element: <MerchantStandardSalesReport />
          },
          {
            path: 'standard-reports/opening-closing-stock-report',
            element: <MerchantOpeningClosingStockReport />
          },
          {
            path: 'standard-reports/payments-report',
            element: <MerchantPaymentsReport />
          },
          {
            path: 'price-lists',
            element: <MerchantAllPriceListReports />
          },
          {
            path: 'products/price/list',
            element: <MerchantProductsPriceList />
          },
          {
            path: 'services/price/list',
            element: <MerchantServicesPriceList />
          },
          {
            path: 'income-statement',
            element: <IncomeStatement />
          },
          {
            path: 'pfms',
            element: <MerchantPfmsStations />
          },
          {
            path: 'pfms/station-reports/:stationId',
            element: <PfmsStationsReports />
          },
          {
            path: 'pfms/station-reports/:stationId/debtors-accounts-position',
            element: <PfmsDailyDebtorsAccountingPosition />
          },
          {
            path: 'pfms/station-reports/:stationId/stock-list',
            element: <PfmsStationStockList />
          },
          {
            path: 'pfms/station-reports/:stationId/cashier-summary-list',
            element: <DailyCashierSummaryList />
          },
          {
            path: 'pfms/station-reports/:stationId/expense-purchases-list',
            element: <DailyExpensePurchaseList />
          },
        ]
      },
      {
        path: 'messages',
        element: (
          <MerchantAdmin />
        ),
        children: [
          {
            path: 'bulk-message',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <BulkMessage />
              </RoleBasedGuard>
            )
          },
          {
            path: 'messages-history',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MessageHistory />
              </RoleBasedGuard>
            )
          }
        ]

      },
      // Booking routes here
      {
        path: 'bookings',
        element: (
          <MerchantAdmin />
        ),
        children: [
          {
            path: 'settings',
            element: (
              <MerchantBookingSettings />
            )
          },
          {
            path: 'ui-settings',
            element: (
              <MerchantBannerSettings />
            )
          },
          {
            path: 'list',
            element: (
              <MerchantBookingList />
            )
          },
          {
            path: 'calendar',
            element: (
              <MerchantCalendarView />
            )
          },
          {
            path: ':bookingId',
            children: [
              {
                path: 'summary',
                element: <BookingSummary />
              }
            ]
          },
        ]
      },
      {
        path: 'crm',
        element: (
          <MerchantAdmin />
        ),
        children: [
          {
            path: 'customers/list',
            element: <MerchantCustomers />,
          },
          {
            path: 'customers/categories',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantCustomersCategories />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'customers/:customerId',
            element: <Customer />,
            children: [
              {
                path: 'summary',
                element: <CustomerSummary />
              },
              {
                path: 'sales-history',
                element: <CustomerSalesHistory />
              },
              {
                path: 'engage-customer',
                element: <EngageCustomer />
              },
              {
                path: 'engage-customer/message-history',
                element: <CustomerMessageHistory />
              },
              {
                path: 'wish-list',
                element: <CustomerWishList />
              },
              {
                path: 'subscriptions',
                element: <CustomerSubscriptions />
              },
            ]
          },
          {
            path: 'customers/import-customers',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantImportCustomers />
              </RoleBasedGuard>
            )
          },
          {
            path: 'customers/custom-fields',
            element: <CustomerCustomFields />
          },
          {
            path: 'subscription-plans',
            element: <MerchantSubscriptionPlans />
          },
        ]
      },
      {
        path: 'plugins',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
            <MerchantAdmin />
          </RoleBasedGuard>
        ),
        children: [
          {
            index: true,
            element: <MerchantPlugins />,
          },
          {
            path: 'woocommerce',
            element: <MerchantWoocommerceIntegration />
          }
        ]
      },
      {
        path: 'settings',
        element: (
          <MerchantAdmin />
        ),
        children: [
          {
            path: 'pos-settings',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantPosSettings />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'bulk-message-settings',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantBulkMessageSettings />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/discounts',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantDiscounts />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/invoice-settings',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <InvoiceSettings />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/tax-rate',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantTaxRate />
              </RoleBasedGuard>
            ),
            children: [
              {
                path: 'etims',
                element: <MerchantEtimsTaxRate />
              },
              {
                path: 'custom',
                element: <MerchantCustomTaxRate />
              },
              {
                path: 'tims',
                element: <MerchantTimsTaxRate />
              },
            ]
          },
          {
            path: 'pos-settings/payment-configuration',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantPaymentConfiguration />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/shift-configuration',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantShiftConfiguration />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/additional-discount-configuration',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantAdditionalDiscountConfiguration />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/delivery-charges',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantDeliveryZones />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'pos-settings/receipt-configuration',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantReceiptConfiguration />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/sale-custom-status-configuration',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <CustomSaleOrderStatus />
              </RoleBasedGuard>
            )
          },
          {
            path: 'pos-settings/stock-settings',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
                <MerchantStockSettings />
              </RoleBasedGuard>
            )
          },
          {
            path: 'notifications',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantNotificationSettings />
              </RoleBasedGuard>
              // <MerchantNotificationSettings />
            )
          },
          {
            path: 'notifications/triggers',
            element: (
              <RoleBasedGuard allowedRoles={[roles.merchantAdmin]}>
                <MerchantNotificationTriggers />
              </RoleBasedGuard>
            )
          }
        ]
      },
      {
        path: 'staff',
        element: (
          <RoleBasedGuard allowedRoles={[roles.merchantAdmin, roles.branchAdmin]}>
            <MerchantAdmin />
          </RoleBasedGuard>
        ),
        children: [
          {
            path: '',
            element: <MerchantStaff />
          },
          {
            path: ':staffId',
            element: <MerchantStaffDetail />,
            children: [
              {
                path: 'summary',
                element: <MerchantStaffInfo />
              },
              {
                path: 'commissions',
                element: <MerchantStaffCommissions />
              }
            ]
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <Invoices />
          },
          {
            path: 'create',
            element: <InvoiceCreate />
          },
          {
            path: 'sales-invoices/:saleID',
            element: <SaleInvoices />,
          },
          {
            path: ':invoiceId',
            children: [
              {
                index: true,
                element: <InvoiceSummary />
              },
              {
                path: 'preview',
                element: <InvoicePreview />
              }
            ]
          }
        ]
      },
      {
        path: 'billing',
        children: [
          {
            index: true,
            element: <MerchantBilling />
          },
          {
            path: 'invoices/:invoiceId',
            element: <MerchantBillingInvoice />
          },
          {
            path: 'transactions/:transactionId',
            element: <MerchantBillingTransaction />
          }
        ]
      },
      {
        path: 'payment',
        element: <MerchantPaymentConfirm />
      },
      {
        path: 'etims/classification-codes',
        element: <EtimsClassCodesTable />
      },
      {
        path: 'etims/items-codes',
        element: <EtimsItemsCodesTable />
      },
      {
        path: 'etims',
        children: [
          {
            index: true,
            element: <EtimsCodes />
          },
          {
            path: 'codes',
            element: <EtimsCodes />
          },
          {
            path: 'info',
            element: <MerchantEtimsInfo />
          },
          {
            path: 'items-codes',
            element: <EtimsItemsCodesTable />
          },
          {
            path: 'classification-codes',
            element: <EtimsClassCodesTable />
          },
          {
            path: 'insurance-list',
            element: <MerchantEtimsInsurance />
          },
        ]
      },

      // {
      //   path: 'users',
      //   children: [
      //     {
      //       path: 'list',
      //       element: <Users />
      //     },
      //     {
      //       path: 'group',
      //       element: <UserGroup />
      //     },
      //     {
      //       path: ':userId',
      //       element: <Customer />,
      //       children: [
      //         {
      //           index: true,,
      //           element: <CustomerSummary />
      //         }
      //       ]
      //     }
      //   ]
      // },
    ]
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: '/waiver-form',
    element: (
      // TODO: add role
      <RoleBasedGuard allowedRoles={[roles.waiverHandler]}>
        <CustomerWaiver />
      </RoleBasedGuard>
    )
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    children: [
      {
        path: ':accessToken',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: 'password-setup',
    children: [
      {
        path: ':accessToken',
        element: <PasswordSetup />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  },
  {
    path: 'suspended',
    element: <SuspendedAccountPage />
  }
];

export default routes;
